import React from "react"

import {
  Navbar,
  Nav,
  Image,
  Container
} from "react-bootstrap"

import ImageLogo from "../images/logo.svg"

const Header = ({ pageInfo }) => {
  return (
    <>
      <div className="header">
        <Container>
          <Navbar variant="light" expand="lg" id="site-navbar" sticky="top">
            <Navbar.Brand href="/#home">
              <Image src={ImageLogo} width="200px" height="auto" alt="Dream Big" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto" activeKey={pageInfo && pageInfo.pageName}>
                <Nav.Link href="/#home">Home</Nav.Link>
                <Nav.Link href="/#accommodation">Smještaj</Nav.Link>
                <Nav.Link href="/#qualifications">Kvalifikacije</Nav.Link>
                <Nav.Link href="/#faq">FAQ</Nav.Link>
                <Nav.Link href="/#contact">Kontakt</Nav.Link>
                <Nav.Link href="/#partners">Partneri</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
    </>
  )
}

export default Header
