import React from "react"
import Obfuscate from "react-obfuscate";
import { Container } from "react-bootstrap"

import { graphql, useStaticQuery } from "gatsby";

const Footer = ({ pageInfo }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  return (
    <>
      <div className="footer">
        <Container>
          <footer>
            <div className="footer-contact">
              General Manager Radovan Popović<br/>
              tel: <Obfuscate tel="+38269811818" /><br/>
              mail: <Obfuscate email="dreambigmne@gmail.com" />
            </div>
            <div>© {new Date().getFullYear()} {site.siteMetadata.title}, All rights reserved.</div>
          </footer>
        </Container>
      </div>
    </>
  )
}

export default Footer
